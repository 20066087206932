import React from "react";
import "../../../../assets/css/forStudentsDetails.css";
import Image1 from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
//import { NavLink } from "react-router-dom";

export default function Who() {
  return (
    <React.Fragment>
      <section className="activities-archives"></section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our history</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    derives from the formerly called the Norwegian school, as it
                    was the haven for the missionaries’ children while the
                    parents were assigned to missional works outside of
                    Antsirabe, L4C has become an NGO since 2011. A “blessed
                    heritage” (translated Lovasoa in Malagasy) was the name
                    chosen for the place as it strives to be for the current
                    generation and the upcoming ones: hosting the archives of
                    shared history of the Malagasy Lutheran Church and the
                    Norwegian Missions Society. L4C offers a privileged green
                    and eco-friendly stay with its loving hospitality and
                    various tasty menus in the restaurant. In addition, both
                    children and adults enjoy the innovative approaches of
                    teaching of music and languages.
                  </p>
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <img src={Image1} alt="" className="img-fluid rounded-4" />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image1} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our vision</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    It is to be a bridge between Madagascar and the whole world
                    through Christian values, outstanding hospitality,
                    cross-cultural and innovative competence building, valuing
                    Malagasy and Norwegian heritage with pride.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our mission</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    It is to promote cross-cultural and Christian values
                    throughout our social and education activities. We also want
                    to build and strengthen international partnerships between
                    Madagascar and other countries especially with Norway by
                    creating a platform for local and international exchange of
                    culture and competences. We aim as well to provide quality
                    accommodation where people feel welcome and secure by
                    offering an eco-friendly stay, and explore new opportunities
                    to extend our zone of intervention.
                  </p>
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <img src={Image1} alt="" className="img-fluid rounded-4" />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-8 col-lg-8 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our core values </span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    <i className="bi bi-x-diamond-fill me-2"></i>
                    Love as it is the fuel that energizes our staff and
                    customers
                  </p>
                  <p className="color-text-a">
                    <i className="bi bi-x-diamond-fill me-2"></i>
                    Teamwork as it is the engine that moves us forward
                  </p>
                  <p className="color-text-a">
                    <i className="bi bi-x-diamond-fill me-2"></i>
                    Excellence as it is what we strive for
                  </p>
                  <p className="color-text-a">
                    <i className="bi bi-x-diamond-fill me-2"></i>
                    Trust as it enhances our notoriety toward our guests and
                    partners
                  </p>
                  <p className="color-text-a">
                    <i className="bi bi-x-diamond-fill me-2"></i>
                    Discipline as it is what we aim to embody in our daily life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
