import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import LayoutCore from "./components/core/layout/LayoutCore";
import HomePage from "./components/core/pages/HomePage";
import ForStudentsDetails from "./components/core/pages/for_students/ForStudentsDetails";
import Board from "./components/core/pages/organization/Board";
import Employees from "./components/core/pages/organization/Employees";
import Founding from "./components/core/pages/organization/Founding";
import Owners from "./components/core/pages/organization/Owners";
import Steering from "./components/core/pages/organization/Steering";
import Who from "./components/core/pages/organization/Who";
import ActivitiesDetails from "./components/core/pages/activities/ActivitiesDetails";
import ServicesDetail from "./components/core/pages/services/ServicesDetail";
import ForVisitor from "./components/core/pages/for_visitor/ForVisitor";
import Blog from "./components/core/pages/blogs/Blog";

function AppRoutes() {
  const routes = useRoutes([
    //Routes Principal
    { path: "/", element: <Navigate to={"/index"} replace /> },

    //Routes Visiteur
    {
      path: "/",
      element: <LayoutCore />,
      children: [
        { path: "index", element: <HomePage />, index:true },
        { path: "activities", element: <ActivitiesDetails /> },
        // for students
        { path: "students", element: <ForStudentsDetails /> },
        // service
        { path: "services", element: <ServicesDetail /> },
        // booking
        { path: "booking", element: <ForVisitor /> },
        // organization
        { path: "board", element: <Board /> },
        { path: "employees", element: <Employees /> },
        { path: "founding", element: <Founding /> },
        { path: "owners", element: <Owners /> },
        { path: "steering", element: <Steering /> },
        { path: "who", element: <Who /> },
        // blog 
        { path: "blog", element: <Blog/> }
      ],
    },
  ]);

  return routes;
}

export default AppRoutes;
