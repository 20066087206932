import React from "react";
import "../../../../assets/css/forStudentsDetails.css";
import Image from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
//import { NavLink } from "react-router-dom";

export default function Founding() {
  return (
    <React.Fragment>
      <section className="activities-archives"></section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our founding members </span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    We are the Malagasy Lutheran Church or FLM and the Norwegian
                    Missions Society or NMS. They initiated the actual project
                    of L4C and have elaborated its statutes and rules of
                    procedures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
