import React from "react";
import "../../../../assets/css/forStudentsDetails.css";
import Image1 from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
//import { NavLink } from "react-router-dom";

export default function Who() {
  return (
    <React.Fragment>
      <section id="details" className="activities-archives"></section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our Board of Directors</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    It is the body of guidance and monitoring, which meets twice
                    a year in May and in November is composed of the following
                    founding members and members: 3 members representing the FLM
                    with two deputies, 3 members representing the NMS with two
                    deputies, 2 members representing the Malagasy partners with
                    two deputies, 2 members representing the Norwegian partners
                    with two deputies, 1 representative of the staff with 1
                    deputy, and the executive director of L4C.
                  </p>
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <img src={Image1} alt="" className="img-fluid rounded-4" />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image1} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-1 d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our General Assembly</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    It is the body of decision-making and deliberation, which
                    meets every year is composed of a maximum of 13
                    representatives from the FLM, a maximum of 13
                    representatives from the NMS, a maximum of 10
                    representatives from the Malagasy partners, a maximum of 10
                    representatives from the foreign partners, and 02 staff
                    representatives of L4C (an executive and a non-executive).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
