import React from "react";
import { Link } from "react-router-dom";

export default function FacebookLink() {
  return (
    <Link
      to="https://www.facebook.com/Lovasoa4c"
      target="_blank"
      className="facebook-link d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-facebook"></i>
    </Link>
  );
}
