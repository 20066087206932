import React from "react";
import "../../../../assets/css/Blog.css";
import Image from "../../../../assets/images/chefs/chefs-1.jpg";

export default function Blog() {
  return (
    <>
    <section id="recent-blog-posts" class="recent-blog-posts section">
      <div class="container py-5">
        <div class="row gy-4">
          <div class="col-lg-4">
            <article class="position-relative post-item h-80">
              <div class="post-img position-relative overflow-hidden">
                <img src={ Image } width={"100%"} height={240} alt=""/>
                <span class="post-date">December 12</span>
              </div>
              <div class="post-content d-flex flex-column">

                <h4 class="post-title">Dolorum optio tempore voluptas dignissimos cumque fuga qui quibusdam quia</h4>

                <div class="meta d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <i class="bi bi-person"></i> <span class="ps-2">John Doe</span>
                  </div>
                </div>

                <p>
                  Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.
                </p>

              </div>
            </article>
          </div>
          
        </div>
      </div>
      </section>
    </>
  );
}
