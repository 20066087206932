import React, { useContext } from "react";
import { Link } from "react-scroll";
import ContextService from "../../../context/ContextService";
import { NavLink } from "react-router-dom";

function FooterCore() {
  // CONTEXT SERVICE ------------------------------
  const {
    loadingGetinTouchBtn,
    resetWindowY,
    getInTouchFormRef,
    saveGetInTouch,
  } = useContext(ContextService);

  return (
    <React.Fragment>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-3 col-6 footer-links text-center text-md-start">
              <h4 className="p-2 mb-3">Organization</h4>
              <ul>
                <li>
                  <NavLink onClick={resetWindowY} to="/who">
                    <i className="bi bi-chevron-right"></i>Who we are
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/board">
                    <i className="bi bi-chevron-right"></i>Board of Director
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/steering">
                    <i className="bi bi-chevron-right"></i>Steering comitee
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/owners">
                    <i className="bi bi-chevron-right"></i>Owners
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/founding">
                    <i className="bi bi-chevron-right"></i>Founding members
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/employees">
                    <i className="bi bi-chevron-right"></i>Employees
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-6  footer-contact text-center text-md-start">
              <h4 className="p-2 mb-2">Contact Us</h4>
              <p>
                Lovasoa Cross-Cultural and Competence Center <br />
                Lot 01 E 30 Rue Stavanger <br />
                BP49, Antsirabe <br />
                <br />
                <strong>Phone:</strong> <br />
                (+261) 34 05 045 25 <br />
                (+261) 20 76 486 85 <br />
                <strong>Email:</strong> guesthouse@lovasoa.mg <br />
              </p>
            </div>

            <div className="col-lg-3 col-6 footer-links text-center text-md-start">
              <h4 className="p-2 mb-3">Discover</h4>
              <ul>
                <li>
                  <NavLink onClick={resetWindowY} to="/index">
                    <i className="bi bi-chevron-right"></i>Home
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/activities">
                    <i className="bi bi-chevron-right"></i>Activities
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/services">
                    <i className="bi bi-chevron-right"></i>Services
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={resetWindowY} to="/students">
                    <i className="bi bi-chevron-right"></i>For Students
                  </NavLink>
                </li>

                <li>
                  <Link to="competence">
                    <i className="bi bi-chevron-right"></i>For visitors
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-9 mx-auto footer-links text-center text-md-start footer-newsletter">
              <div className="single-footer-widget mb-80">
                <h4>Get in touch</h4>
                <form
                  onSubmit={saveGetInTouch}
                  ref={getInTouchFormRef}
                  className="nl-form"
                >
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email..."
                    required
                  />
                  {loadingGetinTouchBtn ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="submit">
                      <i className="bx bx-paper-plane" aria-hidden="true"></i>
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default FooterCore;
